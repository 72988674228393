import { Slot } from '@radix-ui/react-slot';
import { cn } from '@v2/utils/cn';
import { cva, type VariantProps } from 'class-variance-authority';
import { forwardRef } from 'react';
import { Loader } from '../Icon';

export const buttonCva = cva(
  [
    'inline-flex items-center justify-center whitespace-nowrap cursor-pointer',
    'font-sans',
    'rounded-[8px]',
    'focus:shadow-buttonShadow outline-none',
    'hover:duration-200 ease-linear',
  ],
  {
    variants: {
      variant: {
        ghost: 'bg-none focus:ring ',
        primary:
          'bg-primary-500 text-[#fff] hover:bg-primary-600  focus:bg-primary-600 focus:shadow-primaryShadow',
        secondary:
          'text-gray-800 border  border-gray-300  focus:shadow-secondaryShadow hover:bg-gray-50  ',
        tertiary:
          'text-primary-500 bg-none hover:bg-primary-50 hover:text-primary-800 focus:shadow-none ',
        gray: 'text-gray-800 bg-none  focus:shadow-none',
        link: 'text-primary-500 hover:text-primary-600 focus:text-primary-600 ',
      },
      size: {
        '2xl': 'py-[16px] px-[28px] text-[16px] leading-[24px] font-semibold',
        xl: 'py-[12px] px-[20px] text-[16px] leading-[24px] font-semibold',
        lg: 'py-[10px] px-[18px] text-[16px] leading-[24px] font-semibold',
        md: 'py-[10px] px-[16px] text-[14px] leading-[20px] font-semibold',
        sm: 'p-[8px] text-[14px] leading-[20px] font-semibold',
      },
      fullWidth: {
        true: 'w-full',
      },

      disabled: {
        true: 'cursor-not-allowed',
      },
    },

    compoundVariants: [
      {
        variant: 'primary',
        disabled: true,
        class: 'text-gray-400 bg-gray-200 hover:bg-current focus:none',
      },

      {
        variant: 'tertiary',
        disabled: true,
        class: 'text-gray-300 hover:bg-transparent hover:text-gray-300 ',
      },

      {
        variant: 'secondary',
        disabled: true,

        class: 'text-gray-300 focus:none  hover:bg-transparent',
      },

      {
        variant: 'gray',
        disabled: true,
        class: 'text-gray-300 ',
      },
      {
        variant: 'ghost',
        disabled: true,
        class: 'text-gray-300 ',
      },
    ],

    defaultVariants: {
      size: 'lg',
      variant: 'primary',
    },
  }
);

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonCva> & {
    asChild?: boolean;
    isLoading?: boolean;
  };

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, isLoading, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(
          buttonCva({
            size,
            variant,
            disabled: props.disabled || isLoading,
            className,
          })
        )}
        ref={ref}
        {...props}
      >
        {/* TODO TEST WITH DIFFERENT BUTTON SIZE */}

        {isLoading && (
          <Loader
            style={{
              width: '28px',
              height: '28px',
              transform: 'scale(2)',
            }}
          />
        )}
        {!isLoading && props.children}
      </Comp>
    );
  }
);
Button.displayName = 'Button';
