// eslint-disable-next-line @typescript-eslint/no-var-requires
const colors = require('tailwindcss/colors');

delete colors['lightBlue'];
delete colors['warmGray'];
delete colors['trueGray'];
delete colors['coolGray'];
delete colors['blueGray'];

/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ['app/**', 'src/**/*.{ts,tsx}'],
  theme: {
    colors: {},
    extend: {
      colors: {
        ...colors,
        gray: {
          25: '#FBFCFD',
          50: '#F7F8FE',
          100: '#F0F2F5',
          75: '#F7F8FF',
          200: '#E8EAEE',
          300: '#D0D5DD',
          400: '#98A2B3',
          500: '#667085',
          600: '#475467',
          700: '#344054',
          800: '#1D2939',
          900: '#101828',
          950: '#0C111D',
        },
        primary: {
          50: '#FFF1F3',
          100: '#FFE4E8',
          300: '#FAA0AA',
          500: '#FA2846',
          600: '#D5223C',
          800: '#8A1627',
        },
        success: {
          100: '#DCFAE6',
          600: '#079455',
          700: '#067647',
        },
        warning: {
          100: '#FFF2D2',
          700: '#B54708',
        },
        blue: {
          100: '#D1E9FF',
          700: '#175CD3',
        },
      },
      boxShadow: {
        md: '0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
        primaryShadow:
          '0px 0px 0px 4px #FFF1F3, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
        secondaryShadow:
          '0px 0px 0px 4px #F2F4F7, 0px 1px 2px 0px rgba(16, 24, 40, 0.2)',
        shadowLg:
          '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
      },
      keyframes: {
        'caret-blink': {
          '0%,70%,100%': { opacity: '1' },
          '20%,50%': { opacity: '0' },
        },
        fadeInRight: {
          '0%': {
            opacity: '0',
            transform: 'translate(2rem)',
          },
          '100%': {
            opacity: '1',
            transform: 'translate(0)',
          },
        },
        fadeOutLeft: {
          '0%': {
            opacity: '1',
          },
          '100%': {
            opacity: '0',
          },
        },
        'accordion-down': {
          from: { height: '0' },
          to: { height: 'var(--radix-accordion-content-height)' },
        },
        'accordion-up': {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0' },
        },
      },
      letterSpacing: {
        '-0.72': '-0.72px',
      },
      animation: {
        'accordion-down': 'accordion-down 0.2s ease-out',
        'accordion-up': 'accordion-up 0.2s ease-out',
        'caret-blink': 'caret-blink 1.25s ease-out infinite',
        enter: 'fadeInRight 300ms ease-out',
        leave: 'fadeOutLeft 300ms ease-in forwards',
      },
      fontFamily: {
        sans: ['var(--font-manrope)'],
      },
    },
  },
  plugins: [],
};
