'use client';

import * as React from 'react';

import * as RPNInput from 'react-phone-number-input';

import { ChevronDown, Input, InputProps } from '@v2/ui';
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from '@v2/ui/Menubar';
import { cn, themeConfig } from '@v2/utils';

type PhoneInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'value'
> &
  Omit<RPNInput.Props<typeof RPNInput.default>, 'onChange'> & {
    onChange?: (value: RPNInput.Value) => void;
  } & InputProps;

const InputComponent = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, extent, ...props }, ref) => {
    return (
      <Input
        extent={extent}
        style={{ borderLeft: 'none' }}
        className={cn(
          'rounded-s-none pl-[12px] focus:border-gray-300',
          className
        )}
        required
        ref={ref}
        {...props}
      />
    );
  }
);
const PhoneInput: React.ForwardRefExoticComponent<PhoneInputProps> =
  React.forwardRef<React.ElementRef<typeof RPNInput.default>, PhoneInputProps>(
    ({ className, onChange, ...props }, ref) => {
      return (
        <RPNInput.default
          ref={ref}
          className={cn('flex', className)}
          flagComponent={FlagComponent}
          countrySelectComponent={CountrySelect}
          inputComponent={InputComponent}
          /**
           * Handles the onChange event.
           *
           * react-phone-number-input might trigger the onChange event as undefined
           * when a valid phone number is not entered. To prevent this,
           * the value is coerced to an empty string.
           *
           * @param {E164Number | undefined} value - The entered value
           */
          // @ts-ignore
          onChange={(value) => onChange?.(value || '')}
          {...props}
        />
      );
    }
  );
PhoneInput.displayName = 'PhoneInput';

type CountrySelectOption = { label: string; value: RPNInput.Country };

type CountrySelectProps = {
  disabled?: boolean;
  value: RPNInput.Country;
  onChange: (value: RPNInput.Country) => void;
  options: CountrySelectOption[];
};

const CountrySelect = ({
  disabled,
  value,
  onChange,
  options,
}: CountrySelectProps) => {
  const handleSelect = React.useCallback(
    (country: RPNInput.Country) => {
      onChange(country);
    },
    [onChange]
  );

  return (
    <Menubar
      className="h-12 w-[80px] rounded-e-none p-0 shadow-none"
      style={{ borderRight: 'none' }}
    >
      <MenubarMenu>
        <MenubarTrigger asChild>
          <button
            type="button"
            className={cn('gap flex items-center text-[16px] font-normal')}
            disabled={disabled}
          >
            <FlagComponent country={value} countryName={value} />
            <ChevronDown
              size="20"
              className={cn(disabled ? 'hidden' : 'opacity-100')}
              pathProps={{ stroke: themeConfig.theme.colors.gray[500] }}
            />
          </button>
        </MenubarTrigger>
        <MenubarContent className="max-h-[300px] w-[300px] overflow-y-auto bg-white p-0">
          {options.map((option) => (
            <MenubarItem
              className="gap-2"
              key={option.value}
              onSelect={() => handleSelect(option.value)}
            >
              <span className="text-sm">{option.label}</span>
              {option.value && (
                <span className="text-foreground/50 text-sm">
                  ({`+${RPNInput.getCountryCallingCode(option.value)}`})
                </span>
              )}
            </MenubarItem>
          ))}
        </MenubarContent>
      </MenubarMenu>
    </Menubar>
  );
};

const FlagComponent = ({ country, countryName }: RPNInput.FlagProps) => {
  return (
    <span className="bg-foreground/20 flex h-4 w-6 overflow-hidden rounded-sm">
      {country}
    </span>
  );
};
FlagComponent.displayName = 'FlagComponent';

export { PhoneInput };
